import React from 'react'
import styled, { keyframes } from 'styled-components'
import { FaArrowRight } from 'react-icons/fa'
import { isFunction } from 'lodash'
import { IMAGE_PLACEHOLDER } from '../utils/product.service'

const shakeHorizontal = keyframes`

0% {
  transform: translateX(0);
}

50% {
  transform: translateX(-10px);
}

100% {
  transform: translateX(0);
}
`

const BigCardWrapper = styled.div`
  width: 27vw;
  min-width: 265px;
  position: relative;
  display: grid;
  grid-template-rows: 250px 1fr 40px;
  margin: 0px 15px;
  margin-bottom: 25px;
  background-color: var(--light-color);
  color: var(--dark-color);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 5px 5px 25px -5px #0000002b;
  height: 100%;

  .image-container {
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .details-container {
    position: relative;
    grid-row: 2;
    padding: 20px 15px;

    &::before {
      content: '';
      width: 100%;
      height: 40px;
      position: absolute;
      top: -40px;
      left: 0;
      background: linear-gradient(
        0deg,
        var(--light-color),
        var(--dark-color-transperent)
      );
    }

    h3 {
      font-size: 1.4em;
      margin-bottom: 5px;
    }

    p {
      display: -webkit-box;
      max-height: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      white-space: pre-line;
    }
  }

  .button-container {
    cursor: pointer;
    border-top: 1px dashed;
    transition: all 0.2s ease;

    &:hover {
      background: var(--primary-color);
      color: var(--light-color);
      // border-color: var(--primary-color);
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 10px;

      .arrow-icon {
        margin-left: 20px;
        animation: ${shakeHorizontal} 2s ease-in-out infinite;
      }
    }
  }

  @media (max-width: 730px) {
    width: 100%;
    margin-left: 0;
  }
`

/*

BigCard params

  id
  title
  description
  image

  onClickDetails(id)

*/

const BigCard = ({
  id,
  title,
  desciptionText,
  descriptionComponent,
  image,
  buttonText,
  onClickDetail
}) => {
  return (
    <BigCardWrapper>
      <div className="image-container">
        <img src={image ? image : IMAGE_PLACEHOLDER} alt={title} />
      </div>
      <div className="details-container">
        <h3>{title}</h3>
        {descriptionComponent ? descriptionComponent : <p>{desciptionText}</p>}
      </div>
      <div
        className="button-container"
        onClick={() => isFunction(onClickDetail) && onClickDetail(id)}
      >
        <span>
          {buttonText ? buttonText : 'View details'}{' '}
          <FaArrowRight className="arrow-icon" />
        </span>
      </div>
    </BigCardWrapper>
  )
}

export default BigCard
