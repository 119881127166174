import { isEmpty, isNull } from 'lodash'

export const isEmptyModified = data => {
  return !data || isEmpty(data) || isNull(data)
}

const defaultKeyModifier = str => str

export const flattenObj = (ob, keyModifier = defaultKeyModifier) => {
  // The object which contains the
  // final result
  let result = {}

  // loop through the object "ob"
  for (const i in ob) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i])
      for (const j in temp) {
        // Store temp in result
        result[keyModifier(i + '.' + j)] = temp[j]
      }
    }

    // Else store ob[i] in result directly
    else {
      result[keyModifier(i)] = ob[i]
    }
  }
  return result
}

export const convertToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export function isValidHttpUrl(string) {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}
export const prepareImageKitThumbnail = url =>
  url?.replace(/tr:n-ik_ml_thumbnail/g, 'tr:w-0.45')
