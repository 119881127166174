import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import PageTitle from '../components/PageTitle'
import BigCard from '../components/BigCard'
import ScrollAnimationWrapper from '../components/ScrollAnimationWrapper'
import { useNavigate } from 'react-router-dom'
import { ProductCatalogue } from '../utils/product.service'
import { sample } from 'lodash'
import { prepareImageKitThumbnail } from '../utils/common'

const ProductCategoryWrapper = styled.section`
  background-color: var(--dark-color);
  padding: 30px;
  color: var(--primary-color);

  .title {
    color: var(--light-color);
  }

  .products-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 25px 0;

    & > div {
      display: flex;
      justify-content: center;
      flex: 1 1 0;
      margin: 15px 20px;
    }
  }
`

const ProductCategory = () => {
  const [productCategories, setProductCategories] = useState([])
  const navigate = useNavigate()
  const productCatalogueInstance = useMemo(
    () => ProductCatalogue.getInstance(),
    []
  )

  useEffect(() => {
    if (productCatalogueInstance) {
      ;(async () => {
        const productsCategories =
          await productCatalogueInstance.getProductCategory()
        setProductCategories(productsCategories)
      })()
    }
  }, [productCategories, productCatalogueInstance])

  return (
    <ProductCategoryWrapper id="products-container">
      <PageTitle
        title="Products"
        lineBackground={'var(--primary-color-light)'}
      />
      <div className="products-list">
        {productCategories.map((category, index) => {
          const desired_count = Math.floor(window.innerWidth / 335) // 335 is approx width of the card
          const delay = (index % desired_count) * 150
          category.thumbnail = category.thumbnail
            ? category.thumbnail
            : prepareImageKitThumbnail(
                sample(sample(category.items)?.thumbnails)
              )
          return (
            <ScrollAnimationWrapper
              delay={delay}
              style={{
                zIndex: index
              }}
              key={index}
            >
              <BigCard
                title={category.name}
                desciptionText={category.desciption}
                id={category.id}
                onClickDetail={id => id && navigate(`/category/${id}`)}
                buttonText={'View category'}
                image={category.thumbnail}
              />
            </ScrollAnimationWrapper>
          )
        })}
      </div>
    </ProductCategoryWrapper>
  )
}

export default ProductCategory
