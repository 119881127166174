import canalImg from './brands/canal.gif'
import cntdImg from './brands/cntd.png'
import dongnanImg from './brands/dongnan.png'
import highlyImg from './brands/highly.png'
import lightcountryImg from './brands/lightcountry.png'
import omterImg from './brands/omter.png'
import onpowImg from './brands/onpow.png'
import salecomImg from './brands/salecom.png'
import wandaImg from './brands/wanda.png'

const Profiles = {
  brandItems: [
    {
      name: 'Canal Components Inc.',
      src: canalImg
    },
    {
      name: 'CNTD Electric Technology Co. Ltd.',
      src: cntdImg
    },
    {
      name: 'Dongnan Electronics Co. Ltd.',
      src: dongnanImg
    },
    {
      name: 'HIGHLY Electric Co.',
      src: highlyImg
    },
    {
      name: 'Light Country Co. Ltd.',
      src: lightcountryImg
    },
    {
      name: 'Yueqing Omter Electronic & Technology Co. Ltd.',
      src: omterImg
    },
    {
      name: 'ONPOW Push Button Manufacture Co. Ltd.',
      src: onpowImg
    },
    {
      name: 'Salecom Electronics Co. Ltd.',
      src: salecomImg
    },
    {
      name: 'Wenzhou Wanda Electronics Co. Ltd',
      src: wandaImg
    }
  ]
}
export default Profiles
