import styled from 'styled-components'

const NotFoundWrapper = styled.div`
  width: 98vw;
  height: 70vh;

  display: flex;
  justify-content: center;
  align-items: center;
`

const NotFound = () => {
  return (
    <NotFoundWrapper>
      <h1>You are landed in unknown location!</h1>
    </NotFoundWrapper>
  )
}

export default NotFound
