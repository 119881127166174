import React from 'react'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'

const ReactSelectWrapper = ({
  options,
  onChange,
  values,
  inputId,
  isMulti = true,
  isClearable = true,
  isDisabled = false,
  isCreatable = true
}) => {
  return isCreatable ? (
    <CreatableSelect
      className="react-select-dropdown"
      inputId={inputId}
      isMulti={isMulti}
      options={options}
      onChange={onChange}
      value={values}
      isClearable={isClearable}
      isDisabled={isDisabled}
    />
  ) : (
    <ReactSelect
      className="react-select-dropdown"
      inputId={inputId}
      isMulti={isMulti}
      options={options}
      onChange={onChange}
      value={values}
      isClearable={isClearable}
      isDisabled={isDisabled}
    />
  )
}
export default ReactSelectWrapper
