import { isEmpty } from 'lodash'
import axios from 'axios'

export class GithubService {
  static _instance

  static getInstance = function () {
    if (!isEmpty(this._instance)) return this._instance
    this._instance = new GithubService()
    return this._instance
  }

  getRawContent = async function (fileName) {
    const { REACT_APP_GITHUB_REPO_NAME, REACT_APP_GITHUB_OWNER } = process.env
    const response = await axios.get(
      `https://api.github.com/repos/${REACT_APP_GITHUB_OWNER}/${REACT_APP_GITHUB_REPO_NAME}/contents/${fileName}`
    )
    const content = response.data?.content
    return content
      ? JSON.parse(Buffer.from(content, 'base64').toString('utf8'))
      : []
  }

  putContent = async function (fileName, content, commitMessage) {
    const {
      REACT_APP_GITHUB_REPO_NAME,
      REACT_APP_GITHUB_OWNER,
      REACT_APP_GITHUB_BRANCH,
      REACT_APP_GITHUB_TOKEN
    } = process.env
    const url = `https://api.github.com/repos/${REACT_APP_GITHUB_OWNER}/${REACT_APP_GITHUB_REPO_NAME}/contents/${fileName}`

    const headers = {
      Authorization: `bearer ${REACT_APP_GITHUB_TOKEN}`,
      Accept: 'application/vnd.github+json',
      'Content-Type': 'application/json'
    }

    const fileDetails = await axios.get(url, {
      headers
    })

    const sha = fileDetails.data.sha
    const body = {
      message: commitMessage,
      content: new Buffer.from(JSON.stringify(content)).toString('base64'),
      sha,
      branch: REACT_APP_GITHUB_BRANCH
    }

    await axios.put(url, body, {
      headers
    })

    return true
  }
}
