import React from 'react'
import styled from 'styled-components'
import banner from '../assets/banner.mp4'
import Typewriter from 'typewriter-effect'

const HomeWrapper = styled.section`
  height: 93vh;
  width: 100%;
  // background-color: var(--primary-color);
  background-image: radial-gradient(
      circle at 29% 55%,
      hsla(329, 0%, 99%, 0.05) 0%,
      hsla(329, 0%, 99%, 0.05) 4%,
      transparent 4%,
      transparent 44%,
      transparent 44%,
      transparent 100%
    ),
    radial-gradient(
      circle at 85% 89%,
      hsla(329, 0%, 99%, 0.05) 0%,
      hsla(329, 0%, 99%, 0.05) 51%,
      transparent 51%,
      transparent 52%,
      transparent 52%,
      transparent 100%
    ),
    radial-gradient(
      circle at 6% 90%,
      hsla(329, 0%, 99%, 0.05) 0%,
      hsla(329, 0%, 99%, 0.05) 53%,
      transparent 53%,
      transparent 64%,
      transparent 64%,
      transparent 100%
    ),
    radial-gradient(
      circle at 35% 75%,
      hsla(329, 0%, 99%, 0.05) 0%,
      hsla(329, 0%, 99%, 0.05) 6%,
      transparent 6%,
      transparent 98%,
      transparent 98%,
      transparent 100%
    ),
    radial-gradient(
      circle at 56% 75%,
      hsla(329, 0%, 99%, 0.05) 0%,
      hsla(329, 0%, 99%, 0.05) 16%,
      transparent 16%,
      transparent 23%,
      transparent 23%,
      transparent 100%
    ),
    radial-gradient(
      circle at 42% 0%,
      hsla(329, 0%, 99%, 0.05) 0%,
      hsla(329, 0%, 99%, 0.05) 3%,
      transparent 3%,
      transparent 26%,
      transparent 26%,
      transparent 100%
    ),
    radial-gradient(
      circle at 29% 28%,
      hsla(329, 0%, 99%, 0.05) 0%,
      hsla(329, 0%, 99%, 0.05) 51%,
      transparent 51%,
      transparent 75%,
      transparent 75%,
      transparent 100%
    ),
    radial-gradient(
      circle at 77% 21%,
      hsla(329, 0%, 99%, 0.05) 0%,
      hsla(329, 0%, 99%, 0.05) 35%,
      transparent 35%,
      transparent 55%,
      transparent 55%,
      transparent 100%
    ),
    radial-gradient(
      circle at 65% 91%,
      hsla(329, 0%, 99%, 0.05) 0%,
      hsla(329, 0%, 99%, 0.05) 46%,
      transparent 46%,
      transparent 76%,
      transparent 76%,
      transparent 100%
    ),
    linear-gradient(45deg, var(--primary-color-light), var(--primary-color));
  color: var(--light-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    position: absolute;

    h2 {
      font-size: 3rem;
      font-weight: lighter;
      font-variant: small-caps;
    }
  }

  .overlay {
    position: absolute;
    height: 93vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
`

const Home = () => {
  return (
    <HomeWrapper id="home-container">
      <div className="overlay"></div>
      <video autoPlay loop muted>
        <source src={banner} type="video/mp4" />
      </video>
      <center className="content">
        <h2>
          <Typewriter
            options={{
              strings: [
                'Switch to <strong>Switch Point</strong> & <br/> experience the difference!'
              ],
              autoStart: true,
              loop: true,
              pauseFor: 1000 * 60
            }}
          />
        </h2>
      </center>
    </HomeWrapper>
  )
}

export default Home
