import React from 'react'
import styled from 'styled-components'
import PageTitle from '../components/PageTitle'
import AboutPh from '../assets/about-placeholder.png'
import ScrollAnimationWrapper from '../components/ScrollAnimationWrapper'
import ProfileCarousel from '../components/ProfileCarousel'
import Profiles from '../assets/profiles'

const AboutContainer = styled.div`
  background-color: var(--light-color);

  .desc-section {
    display: grid;
    grid-template-columns: auto 40%;
    position: relative;
    overflow: hidden;
    height: 100%;

    .about-content {
      padding: 30px;

      h2 {
        color: var(--dark-color);
      }

      p {
        color: var(--dark-color);
        text-indent: 30px;
        font-size: 20px;
      }
    }

    .image-container img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }

    .image-container {
      position: absolute;
      height: 100%;
      width: 40%;
      right: 0;
      overflow: hidden;
    }
  }

  .profile-sections {
    padding: 10px;
    display: block;
    text-align: center;
    margin: auto;
    margin-top: 25px;

    h3 {
      color: var(--primary-color);
    }

    .profile-content {
      padding: 30px;
    }

    button.slick-prev:before,
    button.slick-next:before {
      color: var(--primary-color);
    }

    .slick-dots {
      bottom: unset;
    }
  }

  @media screen and (max-width: 600px) {
    .desc-section {
      display: flex;
      flex-direction: column-reverse;

      .image-container {
        position: unset;
        width: 100%;
        height: 370px;
      }

      .image-container {
        height: 210%;
      }

      .image-container img {
        object-position: 0% 5%;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .desc-section {
      .image-container {
        height: 270px;
      }

      .image-container {
        height: 170%;
      }
    }
  }
`
const About = () => {
  return (
    <AboutContainer id="about-container">
      <section className="desc-section">
        <div className="about-content">
          <PageTitle title="About us" />
          <ScrollAnimationWrapper delay={200}>
            <p>
              Established in 2004, we are the importers and leading suppliers of
              electronic switches used in all electronic devices. We aim to
              serve all switch requirements across geographies, businesses, and
              product segments.
            </p>
          </ScrollAnimationWrapper>
          <br />
          <ScrollAnimationWrapper>
            <p>
              Our vast catalog of 3000+ varieties provides you with the best
              quality switches, supported with datasheets, test reports,
              certifications, and complete details. And, with our enriched
              experience in this segment of B2B business, it's our constant
              endeavor to provide a better experience to our customers.
            </p>
          </ScrollAnimationWrapper>
        </div>
        <div className="image-container" y={[-40, 40]}>
          <img src={AboutPh} alt="switches" />
        </div>
      </section>

      <section className="profile-sections">
        <ScrollAnimationWrapper delay={200}>
          <h3>Brands That Trust Us</h3>
          <div className="profile-content">
            <ProfileCarousel items={Profiles.brandItems} />
          </div>
        </ScrollAnimationWrapper>
      </section>
    </AboutContainer>
  )
}

export default About
