import React from 'react'
import styled from 'styled-components'
import { Logo } from '../utils/logoProvider'
import ScrollAnimationWrapper from './ScrollAnimationWrapper'

const FooterWrapper = styled.footer`
  background-color: var(--dark-color);
  color: var(--light-color);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 3%;

    .logo-container {
      display: flex;

      img {
        width: 300px;
        fill: white;
        filter: invert(1) hue-rotate(180deg);
      }

      svg {
        width: 300px;
        height: auto;

        .upper-half-logo {
          fill: var(--primary-color);
          stroke: var(--primary-color);
        }

        .lower-half-logo {
          fill: var(--light-color);
          stroke: var(--light-color);
        }
      }

      .name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-size: 20px;

        .upper {
          color: var(--primary-color);
          font-size: 30px;
        }
      }
    }

    .details-container {
      h5 {
        text-align: center;
        margin-bottom: 10px;
        font-size: 20px;
        text-transform: uppercase;
      }

      h6 {
        text-align: center;
        margin-bottom: 8px;
        font-size: 16px;
        text-transform: uppercase;
      }

      a {
        text-decoration: none;
        color: var(--light-color);

        &:hover {
          text-decoration: underline;
        }
      }

      .main-office-container {
        display: flex;
        flex-direction: column;
        text-align: center;

        .address {
          max-width: 400px;
        }

        .contact-group {
          display: flex;
          margin-top: 15px;
          justify-content: space-between;
        }
      }
    }
  }

  .credit {
    align-self: flex-end;
    overflow: hidden;
    padding: 0 10px 10px 0;
    font-size: 12px;
    letter-spacing: 1.7px;

    & > span:first-child {
      opacity: 0.6;
    }

    .love {
      color: var(--primary-color);
    }
    a {
      color: inherit;
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 975px) {
    .content {
      flex-direction: column;

      .details-container {
        margin-top: 20px;
      }
    }
  }

  @media screen and (max-width: 270px) {
    .content {
      .logo-container {
        flex-direction: column;
        justify-content: center;

        .name {
          align-items: center;
        }
      }
    }
  }
`

const Footer = () => (
  <FooterWrapper>
    <div className="content">
      <ScrollAnimationWrapper>
        <div className="logo-container">
          <div className="logo">
            <Logo />
          </div>
          <div className="name hidden">
            <span className="upper">Switch</span>
            <span className="lower">Point</span>
          </div>
        </div>
      </ScrollAnimationWrapper>

      <div className="details-container">
        <ScrollAnimationWrapper delay={50}>
          <div className="main-office-container">
            <h5>Head Office</h5>
            <a href="https://goo.gl/maps/nLKBZxtbQszycoPr7" className="address">
              33, 1<sup>st</sup> Floor, Plot - 1140, Vijay Chambers, Tribhuwan
              Marg, Dreamland Cinema, Grant Road, Girgaon, Mumbai-400007,
              Maharashtra, India
            </a>
            <div className="contact-group">
              <span className="contact-item">
                <h6>Email</h6>
                <span className="contact-details">
                  <a href="mailto:switchpoint2004@gmail.com">
                    switchpoint2004@gmail.com
                  </a>
                </span>
              </span>
              <span className="contact-item">
                <h6>Phone</h6>
                <span className="contact-details">
                  <a href="tel:+919820580406">+91-9820-580-406</a>
                </span>
              </span>
            </div>
          </div>
        </ScrollAnimationWrapper>
      </div>
    </div>
    <div className="credit animate__animated animate__repeat-3 animate__flash">
      <span>
        Handcrafted with <span className="love">♥</span> by{' '}
      </span>
      <span>
        <a
          href="https://www.linkedin.com/in/viral-limbani-6bb265a3/"
          target="_blank"
          rel="noreferrer"
        >
          Viral
        </a>
      </span>{' '}
      and{' '}
      <span>
        <a
          href="https://www.linkedin.com/in/er-raoniz/"
          target="_blank"
          rel="noreferrer"
        >
          Rahul
        </a>
      </span>
    </div>
  </FooterWrapper>
)

export default Footer
