import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { isEmpty } from 'lodash'

export class UserService {
  static _instance = null

  static getInstance = function () {
    if (this._instance) return this._instance
    this._instance = new UserService()
    return this._instance
  }

  setAccessToken(access_token) {
    localStorage.setItem('access_token', access_token)
  }

  removeAccessToken() {
    localStorage.removeItem('access_token')
  }

  getAccessTokenData() {
    const access_token = localStorage.getItem('access_token')
    if (isEmpty(access_token)) return null // token not found
    const decoded = jwtDecode(access_token)
    if (Date.now() >= decoded.exp * 1000) return null // token expired

    return decoded
  }

  async login(email, password) {
    const {
      REACT_APP_AUTH0_CLIENT_ID,
      REACT_APP_AUTH0_BASE_URL,
      REACT_APP_AUTH0_AUDIENCE
    } = process.env

    try {
      const response = await axios.post(
        `${REACT_APP_AUTH0_BASE_URL}/oauth/token`,
        {
          username: email,
          password: password,
          scope: 'openid offline_access',
          audience: REACT_APP_AUTH0_AUDIENCE,
          realm: 'Username-Password-Authentication',
          client_id: REACT_APP_AUTH0_CLIENT_ID,
          grant_type: 'http://auth0.com/oauth/grant-type/password-realm'
        }
      )

      if (response.data.access_token) {
        this.setAccessToken(response.data.access_token)
        return { success: true }
      } else {
        return { success: false, message: 'Something went wrong!' }
      }
    } catch ({ response }) {
      if (
        response?.status === 403 &&
        response?.data?.error === 'invalid_grant'
      ) {
        return { success: false, message: response.data.error_description }
      } else {
        return {
          success: false,
          message: 'Something went wrong!'
        }
      }
    }
  }
}
