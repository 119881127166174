import styled from 'styled-components'

const TextInputStyled = styled.input`
  width: 100%;
  padding: 8px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  font-size: 16px;

  &:hover {
    border-color: hsl(0, 0%, 70%);
  }

  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }

  &[disabled] {
    background-color: #f2f2f2;
    border-color: #e6e6e6;
  }
`

export const TextInput = ({ ...props }) => {
  return <TextInputStyled type="text" placeholder="Type..." {...props} />
}
