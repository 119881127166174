import { sample } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import BigCard from '../components/BigCard'
import PageTitle from '../components/PageTitle'
import ScrollAnimationWrapper from '../components/ScrollAnimationWrapper'
import { prepareImageKitThumbnail } from '../utils/common'
import { ProductCatalogue } from '../utils/product.service'
import NotFound from './NotFound'

const CategoryDetailsWrapper = styled.div`
  padding: 35px;

  .products-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 25px 0;

    & > div {
      margin-bottom: 25px;
    }
  }
`

const CategoryDetails = () => {
  const [products, setProducts] = useState([])
  const [categoryName, setCategoryName] = useState([])
  const navigate = useNavigate()
  const { categoryId } = useParams()
  const productCatalogueInstance = useMemo(
    () => ProductCatalogue.getInstance(),
    []
  )

  useEffect(() => {
    ;(async () => {
      if (categoryId) {
        const category = await productCatalogueInstance.getCategoryFromId(
          categoryId
        )
        setCategoryName(category.name)
        setProducts(category.items)
      }
    })()
  }, [categoryId, productCatalogueInstance])

  if (!categoryId) return <NotFound />

  return (
    <CategoryDetailsWrapper>
      <PageTitle title={`${categoryName}`} />
      <div className="products-list">
        {products.map((product, index) => {
          const desired_count = Math.floor(window.innerWidth / 335) // 335 is approx width of the card
          const delay = (index % desired_count) * 150
          product.thumbnail = product.thumbnail
            ? prepareImageKitThumbnail(product.thumbnail)
            : prepareImageKitThumbnail(sample(product.thumbnails))
          return (
            <ScrollAnimationWrapper
              delay={delay}
              style={{
                zIndex: index
              }}
              key={index}
            >
              <BigCard
                title={product.name}
                desciptionText={`Price: ${product.price}`}
                id={product.id}
                onClickDetail={id => id && navigate(`product/${id}`)} // note: "/" is not prefixed cause the route is continous (not from root)
                buttonText={'View product'}
                image={product.thumbnail}
              />
            </ScrollAnimationWrapper>
          )
        })}
      </div>
    </CategoryDetailsWrapper>
  )
}

export default CategoryDetails
