import { isArray } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import ImageGallery from 'react-image-gallery'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { JsonTable } from '../components/JsonTable'
import { IMAGE_PLACEHOLDER, ProductCatalogue } from '../utils/product.service'
import NotFound from './NotFound'

const ProductDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  color: var(--dark-color);

  .product-details-container {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 50px;

    .details-container {
      flex: 4;
    }

    .images-container {
      padding: 0 10px;
      flex: 3;

      .image-gallery-content .image-gallery-slide .image-gallery-image,
      .placeholder-image {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }

      .image-gallery-left-nav .image-gallery-svg,
      .image-gallery-right-nav .image-gallery-svg {
        width: 25px;
      }
    }

    .property-name {
      font-weight: bold;
      color: var(--primary-color);
    }

    .property-value {
      font-style: italic;
    }

    .basic-details,
    .variable-details,
    .additional-details {
      margin: 15px 0;
    }

    .additional-details {
      p {
        margin: 10px 0;
        text-indent: 5ch;
      }

      .list {
        margin: 10px 0;
        li {
          margin-left: 18px;
        }
      }
    }

    .basic-details {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      & > p {
        width: 50%;
        padding: 3px 0;
      }
    }

    .product-title {
      color: var(--dark-color);
    }

    .sub-component-title {
      margin-bottom: 8px;
      color: var(--dark-color);
    }
  }

  @media (max-width: 716px) {
    .product-details-container {
      flex-direction: column;
      align-items: stretch;
      padding: 20px;

      .images-container {
        margin-bottom: 10px;
        padding: unset;

        .image-gallery-content .image-gallery-slide .image-gallery-image {
          height: 500px;
        }
      }
    }
  }

  @media (max-width: 610px) {
    .product-details-container {
      .images-container {
        .image-gallery-content .image-gallery-slide .image-gallery-image {
          height: 350px;
        }
      }
    }
  }
`

const ProductDetails = () => {
  const [product, setProduct] = useState({})
  const { productId, categoryId } = useParams()
  const productCatalogueInstance = useMemo(
    () => ProductCatalogue.getInstance(),
    []
  )

  useEffect(() => {
    ;(async () => {
      if (productId && categoryId) {
        const product =
          await productCatalogueInstance.getProductOfCategoryFromId(
            categoryId,
            productId
          )
        setProduct(product)
      }
    })()
  }, [productId, categoryId, productCatalogueInstance])

  if (!productId || !categoryId) return <NotFound />
  return (
    <ProductDetailsWrapper>
      <div className="product-details-container">
        <div className="images-container">
          {product.images?.length > 0 ? (
            <ImageGallery
              items={product.images.map((image, index) => ({
                original: image,
                thumbnail: product.thumbnails[index]
              }))}
              lazyLoad
              autoPlay
              showFullscreenButton={false}
            />
          ) : (
            <img
              className="placeholder-image"
              src={IMAGE_PLACEHOLDER}
              alt={`Not found`}
              title={'Image not available'}
            />
          )}
        </div>
        <div className="details-container">
          <h1 className="product-title">{product.name}</h1>
          <div className="basic-details">
            <p>
              <span className="property-name">Price:</span>{' '}
              <span className="property-value">
                {product.currency} {product.price}
              </span>
            </p>
            <p>
              <span className="property-name">Minimum order quantity:</span>{' '}
              <span className="property-value">{product.MOQ}</span>
            </p>
            <p>
              <span className="property-name">Color:</span>{' '}
              <span className="property-value">{product.color}</span>
            </p>
            <p>
              <span className="property-name">Make:</span>{' '}
              <span className="property-value">{product.make}</span>
            </p>
          </div>
          <div className="variable-details">
            <h3 className="sub-component-title">Specifications:</h3>
            <JsonTable jsonData={product.specifications} />
          </div>
          {(product.additional_attributes || product.extra_info) && (
            <div className="additional-details">
              <h4>Additional information:</h4>
              <p>{product.extra_info}</p>
              <ul className="list">
                {isArray(product.additional_attributes) &&
                  product.additional_attributes.map(attribute => (
                    <li>{attribute}</li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </ProductDetailsWrapper>
  )
}

export default ProductDetails
