import { isEmpty, sample } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FaPencilAlt, FaPlus, FaTrash } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ModalWrapper } from '../components/ModalWrapper'
import { Spinner } from '../components/Spinner'
import { Table } from '../components/Table'
import { IMAGE_PLACEHOLDER, ProductCatalogue } from '../utils/product.service'

const AdminWrapper = styled.div`
  padding: 35px;
  color: var(--dark-color);

  .product-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
  }

  .button {
    padding: 8px 12px;
    transition: all 0.2s;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .button.danger {
    color: white;
    background-color: var(--red-color);
    &:hover {
      color: var(--red-color);
      background-color: white;
      box-shadow: 0 0 12px -5px var(--red-color);
    }
  }

  .button.primary {
    color: var(--light-color);
    background-color: var(--primary-color);
    &:hover {
      color: var(--primary-color);
      background-color: var(--light-color);
      box-shadow: 0 0 12px -5px var(--primary-color);
    }
  }

  .product-action-buttons {
    button {
      margin: 5px;
    }
  }

  .product-image-thumbnail {
    width: 50px;
    height: 50px;
  }

  .table-container {
    overflow-x: auto;

    table {
      width: 100%;
    }
  }
`

const DeleteModalContainer = styled.div`
  padding: 20px;

  p {
    padding: 20px 0;
    border: 0.1px dotted var(--light-color);
    margin: 20px 0;
    border-left: 0;
    border-right: 0;
  }

  button:not(:first-child) {
    margin-left: 10px;
  }

  .button {
    padding: 8px 12px;
    transition: all 0.2s;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .button.danger {
    color: white;
    background-color: var(--red-color);
    &:hover {
      color: var(--red-color);
      background-color: white;
      box-shadow: 0 0 12px -5px var(--red-color);
    }
  }

  .button.primary {
    color: var(--light-color);
    background-color: var(--primary-color);
    &:hover {
      color: var(--primary-color);
      background-color: var(--light-color);
      box-shadow: 0 0 12px -5px var(--primary-color);
    }
  }
`

const AdminForm = () => {
  const [loading, setLoading] = useState(true)
  const [productCatalogue, setProductCatalogue] = useState([])
  const [deleteRecord, setDeleteRecord] = useState(undefined)
  const productCatalogueInstance = useMemo(
    () => ProductCatalogue.getInstance(),
    []
  )

  const navigate = useNavigate()

  const toAddProductPage = () => {
    let path = '/admin/product/add'
    navigate(path)
  }

  const fetchProductCatalogue = useCallback(
    async function () {
      setLoading(true)
      const productCatalogue =
        await productCatalogueInstance.getProductCategory()

      productCatalogue.forEach(category => {
        category.items.forEach(item => {
          // TODO: get thumbnail URL
          item.thumbnail = sample(item.thumbnails)
        })
      })
      setProductCatalogue(productCatalogue)
      setLoading(false)
    },
    [productCatalogueInstance]
  )

  const editProduct = useCallback(
    function (productId, categoryId) {
      navigate(`/admin/product/edit/${categoryId}/${productId}`)
    },
    [navigate]
  )

  const deleteProduct = useCallback(
    async function () {
      await productCatalogueInstance.deleteProduct(
        deleteRecord.productId,
        deleteRecord.categoryId
      )
      setDeleteRecord(undefined)
      fetchProductCatalogue()
    },
    [productCatalogueInstance, deleteRecord, fetchProductCatalogue]
  )

  const getDeleteConfirmation = useCallback(function (
    productId,
    categoryId,
    name
  ) {
    setDeleteRecord({
      productId,
      name,
      categoryId
    })
  },
  [])

  const productTableHeader = useMemo(
    () => [
      {
        Header: 'Product Id',
        accessor: 'id'
      },
      {
        Header: 'Sample image',
        accessor: 'thumbnail',
        Cell: ({ cell }) => (
          <center>
            <img
              className="product-image-thumbnail"
              src={
                cell.row.values.thumbnail
                  ? cell.row.values.thumbnail
                  : IMAGE_PLACEHOLDER
              }
              alt={cell.row.values.name}
            />
          </center>
        )
      },
      {
        Header: 'Product Name',
        accessor: 'name'
      },
      {
        Header: 'Category Name',
        accessor: 'category_name'
      },
      {
        Header: 'Category id',
        accessor: 'category_id'
      },
      {
        Header: 'Actions',
        accessor: 'action',
        Cell: ({ cell }) => (
          <center className="product-action-buttons">
            <button
              className="button primary"
              value={cell.row.values.category_id}
              onClick={() =>
                editProduct(cell.row.values.id, cell.row.values.category_id)
              }
            >
              <FaPencilAlt />
            </button>

            <button
              className="button danger"
              value={cell.row.values.category_id}
              onClick={() =>
                getDeleteConfirmation(
                  cell.row.values.id,
                  cell.row.values.category_id,
                  cell.row.values.name
                )
              }
            >
              <FaTrash />
            </button>
          </center>
        )
      }
    ],
    [editProduct, getDeleteConfirmation]
  )

  useEffect(() => {
    fetchProductCatalogue()
  }, [fetchProductCatalogue])

  if (loading) return <Spinner fullPage size={'50px'} />

  return (
    <>
      <ModalWrapper
        open={!isEmpty(deleteRecord)}
        onClose={() => setDeleteRecord(undefined)}
        closeOnOutsideClick
      >
        <DeleteModalContainer>
          <h3>Deleting {deleteRecord?.name} !!</h3>
          <p> You sure want to delete this product? </p>
          <button className="button primary" onClick={deleteProduct}>
            Delete
          </button>
          <button
            className="button danger"
            onClick={() => setDeleteRecord(undefined)}
          >
            Cancel
          </button>
        </DeleteModalContainer>
      </ModalWrapper>
      <AdminWrapper>
        <div className="product-title">
          <h2>Existing products</h2>
          <button
            className="button primary"
            type="button"
            onClick={toAddProductPage}
          >
            <FaPlus /> Add Product
          </button>
        </div>
        {productCatalogue.length <= 0 ? (
          <div>No products available!!</div>
        ) : (
          <Table
            columns={productTableHeader}
            data={[].concat.apply(
              [],
              productCatalogue.map(category => category.items)
            )}
            hiddenColumns={['category_id']}
            paginated
          />
        )}
      </AdminWrapper>
    </>
  )
}

export default AdminForm
