import { usePagination, useTable } from 'react-table'
import styled from 'styled-components'
import { isEmptyModified } from '../utils/common'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import ReactSelectWrapper from './ReactSelectWrapper'

const TableWrapper = styled.div`
  .display-none {
    display: none;
  }

  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination-controls {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;

    .nav-control {
      color: var(--primary-color);

      &.disabled {
        opacity: 0.8;
        pointer-events: none;
      }
    }

    .page-details {
      .react-select-dropdown {
        display: inline-block;
        margin: 0 10px;
      }
    }
  }
`

export const Table = ({
  columns,
  data,
  paginated,
  _pageIndex,
  _pageSize,
  hiddenColumns
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      initialState: { _pageIndex, _pageSize, hiddenColumns }
    },
    usePagination
  )

  // return if empty
  if (isEmptyModified(columns) || isEmptyModified(data)) return null

  return (
    <TableWrapper>
      <div className="table-container">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps()}
                    rowSpan={column.rowSpan ?? 1}
                    className={column.displayNone ? 'display-none' : ''}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {paginated && (
        <div className="pagination-controls">
          <div
            className={`nav-control left ${!canPreviousPage ? 'disabled' : ''}`}
            onClick={() => canPreviousPage && previousPage()}
          >
            <FaChevronLeft />
          </div>
          <div className="page-details">
            Page:
            <ReactSelectWrapper
              isDisabled={pageCount === 1}
              isCreatable={false}
              isClearable={false}
              isMulti={false}
              options={Array.from(Array(pageCount).keys()).map(number => ({
                label: number + 1,
                value: number + 1
              }))}
              values={{ label: pageIndex + 1, value: pageIndex + 1 }}
              onChange={option => {
                gotoPage(option.value - 1)
              }}
            />
            of <span className="bold">{pageOptions.length}</span>
          </div>
          <div
            className={`nav-control right ${!canNextPage ? 'disabled' : ''}`}
            onClick={() => canNextPage && nextPage()}
          >
            <FaChevronRight />
          </div>
        </div>
      )}
    </TableWrapper>
  )
}

Table.defaultProps = {
  paginated: false,
  _pageIndex: 0,
  _pageSize: 10,
  hiddenColumns: []
}
