import { isArray } from 'lodash'
import { useEffect, useState } from 'react'
import { isEmptyModified } from '../utils/common'
import {
  getColumnsFromJsonData,
  getDataFromJsonData
} from '../utils/jsonToReactTable'
import { Table } from './Table'

export const JsonTable = ({ jsonData }) => {
  const [columns, setColumns] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    if (!isEmptyModified(jsonData)) {
      const columns = getColumnsFromJsonData(jsonData)
      let data = getDataFromJsonData(jsonData)

      if (!isArray(data)) {
        data = [data]
      }

      // console.log(columns, data)

      setColumns(columns)
      setData(data)
    }
  }, [jsonData])

  // return if empty
  if (isEmptyModified(jsonData)) return null

  return <Table columns={columns} data={data} />
}
