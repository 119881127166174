import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import ReactSelectWrapper from '../components/ReactSelectWrapper'
import { nanoid } from 'nanoid'
import { cloneDeep, isEmpty, isNull, isUndefined } from 'lodash'
import { TextInput } from '../components/TextInput'
import {
  ALLOWED_FILE_SIZE_IN_BYTES,
  ALLOWED_IMAGE_EXTENSIONS,
  ProductAttributes,
  ProductCatalogue
} from '../utils/product.service'
import { Spinner } from '../components/Spinner'
import { convertToBase64 } from '../utils/common'
import toast, { Toaster } from 'react-hot-toast'

const FormWrapper = styled.div`
  padding: 50px;

  .product-details-fields,
  .specifications-fields,
  .custom-attributes-fields {
    column-count: 3;
    column-gap: 35px;
  }

  h3 {
    color: var(--dark-color);
    text-decoration: bold;
    margin: 8px 0;
  }

  .specifications {
    margin: 60px 0 0;
  }

  .input-field {
    margin: 5px 0px 2px 0;
    width: 100%;
    display: inline-block;
  }

  .input-key-cancel-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      flex: 1;
    }

    span {
      margin-left: 5px;
    }
  }

  .input-label {
    display: inline-block;
    margin-bottom: 8px;
  }

  .text-input {
    border-width: 0px;
    padding: 0px;
    font-size: 18px;

    &:focus {
      margin: 8px 0;
      font-size: unset;
      padding: 8px;
      border-width: 1px;
    }
  }

  .error-text {
    font-size: small;
    color: var(--red-color);
    display: block;
    transition: all 0.2s ease-in-out;
    min-height: 15px;

    &.show {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
    }
  }

  .product-image-input {
    display: none;
  }

  .selected-images-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .uploaded-image-preview {
      display: flex;
      position: relative;
      margin: 10px 10px;
      flex: 1 1 auto;

      .image-detail {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;
        border: 1px dashed var(--primary-color-light);
        border-radius: 4px;
        width: 100%;
      }

      img {
        width: 50px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }

  .product-image-label {
    margin-bottom: 5px;
  }

  .button {
    display: inline-block;
    background-color: var(--primary-color);
    border-radius: 4px;
    padding: 10px;
    color: var(--light-color);
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s ease-out;
    border: none;

    &:hover {
      box-shadow: 2px 2px 10px -4px var(--primary-color-light);
      transform: scale(1.02);
    }
  }

  button {
    margin: 20px 0;
  }

  .cross-icon.top-right {
    position: absolute;
    right: -12px;
    top: -12px;
  }

  .cross-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: var(--red-color);
    border-radius: 50%;
    opacity: 0.9;
    color: white;
    text-align: center;
    padding: 4px;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover {
      box-shadow: 0 0 5px 0 var(--red-color);
    }
  }

  .toast {
    background-color: var(--dark-color);
    color: var(--light-color);
  }

  @media (max-width: 780px) {
    .product-details-fields,
    .specifications-fields,
    .custom-attributes-fields {
      column-count: 2;
    }
  }

  @media (max-width: 570px) {
    .product-details-fields,
    .specifications-fields,
    .custom-attributes-fields {
      column-count: 1;
    }
  }
`

const ProductForm = () => {
  const [loadingConfigurations, setLoadingConfigurations] = useState(true)
  const [productDetails, setProductDetails] = useState([])
  const [specifications, setSpecifications] = useState([])
  const [customAttributes, setCustomAttributes] = useState([])
  const [updateLoading, setUpdateLoading] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [imageError, setImageError] = useState(null)
  const productDetailDiv = useRef(null),
    imagesDiv = useRef(null)

  const productCatalogueInstance = useMemo(
    () => ProductCatalogue.getInstance(),
    []
  )
  const productAttributeInstance = useMemo(
    () => ProductAttributes.getInstance(),
    []
  )

  const fetchProductAttributes = useCallback(
    async function () {
      setLoadingConfigurations(true)
      const productAttributes = await productAttributeInstance.getAttributes()
      const productDetails = productAttributes.product_details.map(attr => ({
        ...attr,
        value: undefined,
        error: false,
        errorMessage: null,
        multiple: attr.multiple ?? true
      }))

      const specifications = productAttributes.specifications.map(attr => ({
        ...attr,
        value: undefined,
        error: false,
        errorMessage: null,
        multiple: attr.multiple ?? true
      }))

      setProductDetails(productDetails)
      setSpecifications(specifications)
      setSelectedImages([])
      setLoadingConfigurations(false)
    },
    [productAttributeInstance]
  )

  useEffect(() => {
    fetchProductAttributes()
  }, [fetchProductAttributes])

  const changeValueForProductDetails = useCallback(
    function (key, value) {
      const productDetailsCopy = cloneDeep(productDetails)
      const index = productDetailsCopy.findIndex(pd => pd.key === key)
      productDetailsCopy[index].value = value
      productDetailsCopy[index].error = false
      setProductDetails(productDetailsCopy)
    },
    [productDetails]
  )

  const changeValueForSpecification = useCallback(
    function (key, value) {
      const specificationsCopy = cloneDeep(specifications)
      const index = specificationsCopy.findIndex(pd => pd.key === key)
      specificationsCopy[index].value = value
      setSpecifications(specificationsCopy)
    },
    [specifications]
  )

  const addEmptyCustomAttribute = useCallback(
    function () {
      setCustomAttributes([
        ...customAttributes,
        {
          identifier: nanoid(),
          multiple: true,
          key: '',
          label: '', // needs to be filled when saving
          options: [],
          value: []
        }
      ])
    },
    [customAttributes]
  )

  const changePropertyForCustomAttribute = useCallback(
    function (identifier, newValues, propertyName) {
      const customAttributesCopy = cloneDeep(customAttributes)
      for (let i = 0; i < customAttributesCopy.length; i++) {
        const attr = customAttributesCopy[i]
        if (attr.identifier === identifier) {
          attr[propertyName] = newValues
          break
        }
      }

      setCustomAttributes(customAttributesCopy)
    },
    [customAttributes]
  )

  const removeCustomAttribute = useCallback(
    function (identifier) {
      const updatedCustomAttribute = customAttributes.filter(
        attr => attr.identifier !== identifier
      )
      setCustomAttributes(updatedCustomAttribute)
    },
    [customAttributes]
  )

  const convertToStorableAttribute = useCallback(function (detail) {
    const detailToUpdate = {
      key: detail.key,
      label: detail.label,
      type: detail.type,
      updateAllowed: detail.updateAllowed
    }

    if (detail.type !== 'text') {
      detailToUpdate.options = [...detail.options]
      if (detail.multiple) {
        detailToUpdate.options = detailToUpdate.options.concat(
          detail.value
            ?.filter(v => v.__isNew__)
            .map(v => ({ value: v.value, label: v.label })) ?? []
        )
      } else if (detail.value?.__isNew__) {
        detailToUpdate.options.push({
          value: detail.value.value,
          label: detail.value.label
        })
      }
      detailToUpdate.multiple = detail.multiple
    }

    return detailToUpdate
  }, [])

  const addImages = useCallback(
    function (imageFiles) {
      if (imageFiles.files.length + selectedImages.length > 5) {
        setImageError('At max 5 images can be uploaded')
        return
      }
      const imageObjects = []
      for (const image of imageFiles.files) {
        const name = image.name
        const extension = name.substring(name.lastIndexOf('.') + 1, name.length)

        if (!ALLOWED_IMAGE_EXTENSIONS.includes(extension.toLowerCase())) {
          setImageError(`File ${name} is not allowed from seelcted files`)
          return
        }

        if (image.size > ALLOWED_FILE_SIZE_IN_BYTES) {
          setImageError(
            `File ${name} have greater size than limit ${
              ALLOWED_FILE_SIZE_IN_BYTES / 1000000
            } MB`
          )
          return
        }
        imageObjects.push({
          extension,
          name,
          file: image
        })
      }
      setImageError(null)
      setSelectedImages(selectedImages => [...selectedImages, ...imageObjects])
    },
    [setSelectedImages, selectedImages]
  )

  const removeImage = useCallback(
    function (imageIndex) {
      setSelectedImages(selectedImages =>
        selectedImages.filter((_, i) => i !== imageIndex)
      )
    },
    [setSelectedImages]
  )

  // FORM SUBMITTION
  const handleSubmit = useCallback(
    async function (e) {
      try {
        e.preventDefault()
        setUpdateLoading(true)

        if (selectedImages.length <= 0) {
          setImageError('At least one image is mandatory.')
          window.scrollTo(0, (imagesDiv.current?.offsetTop || 80) - 80)
          return
        }

        let productDetailserror = false
        let isNewCategory = false

        const updatedProductDetails = []
        const updatedCustomAttribute = []
        const updatedSpecifications = []

        const product = {
          specifications: {}
        }

        await Promise.all(
          productDetails.map(async attr => {
            // To update product catalogue
            const val = attr.value
            if (isEmpty(val) || isNull(val) || isUndefined(val)) {
              productDetailserror = true
              attr.error = true
              attr.errorMessage = `${attr.label} cannot be empty`
            } else if (attr.key === 'id') {
              const isUnique =
                await productCatalogueInstance.checkUniqueProductId(val)
              if (!isUnique) {
                productDetailserror = true
                attr.error = true
                attr.errorMessage = `${attr.label} is not unique`
              }
              product['id'] = val
            } else if (attr.key === 'category_id') {
              if (val.__isNew__) {
                const categoryId = nanoid()
                val.value = categoryId
                isNewCategory = true
              }

              product['category_id'] = val.value
              product['category_name'] = val.label
            } else {
              product[attr.key] =
                attr.type === 'text'
                  ? val
                  : attr.multiple
                  ? val.map(v => v.value).join(',')
                  : val.value
            }

            // To update product attributes
            updatedProductDetails.push(convertToStorableAttribute(attr))
          })
        )

        if (productDetailserror) {
          setProductDetails(cloneDeep(productDetails))
          window.scrollTo(0, (productDetailDiv.current?.offsetTop || 80) - 80)
          return
        }

        specifications.forEach(attr => {
          const val = attr.value
          if (!(isEmpty(val) || isNull(val) || isUndefined(val))) {
            product['specifications'][attr.key] =
              attr.type === 'text'
                ? val
                : attr.multiple
                ? val.map(v => v.value).join(',')
                : val.value
          }

          updatedSpecifications.push(convertToStorableAttribute(attr))
        })

        customAttributes.forEach(attr => {
          const val = attr.value
          if (!(isEmpty(val) || isNull(val) || isUndefined(val))) {
            product['specifications'][attr.key] =
              attr.type === 'text'
                ? val
                : attr.multiple
                ? val.map(v => v.value).join(',')
                : val.value
          }

          updatedCustomAttribute.push(
            convertToStorableAttribute({ ...attr, label: attr.key })
          )
        })

        const UploadedImages = await Promise.all(
          selectedImages.map(async image => {
            const imageFileName = `product_${product.id}_${product.category_id}.${image.extension}`
            const base64Img = await convertToBase64(image.file)
            const imageTags = [product.category_name]
            return productCatalogueInstance.uploadImage(
              base64Img,
              imageFileName,
              imageTags
            )
          })
        )

        await productAttributeInstance.updateProductAttributes(
          {
            product_details: updatedProductDetails,
            specifications: updatedSpecifications.concat(updatedCustomAttribute)
          },
          product.id
        )

        product['images'] = UploadedImages.map(image => image.url)
        product['thumbnails'] = UploadedImages.map(image => image.thumbnailUrl)
        product['imageData'] = UploadedImages.map(
          ({ url, thumbnailUrl, ...rest }) => rest
        )

        await productCatalogueInstance.updateNewProduct(product, isNewCategory)
        toast.success('Added product successfully!!')
        fetchProductAttributes()
      } catch (error) {
        // eslint-disable-next-line
        console.error(error)
        toast.error('Opps! Something went wrong!')
      } finally {
        setUpdateLoading(false)
      }
    },
    [
      convertToStorableAttribute,
      setImageError,
      selectedImages,
      customAttributes,
      productAttributeInstance,
      productCatalogueInstance,
      specifications,
      productDetails,
      fetchProductAttributes
    ]
  )

  if (loadingConfigurations) return <Spinner fullPage size={'50px'} />

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            background: 'var(--dark-color)',
            color: 'var(--light-color)'
          }
        }}
        reverseOrder={false}
      />
      <FormWrapper>
        <form onSubmit={handleSubmit}>
          <div ref={productDetailDiv} className="product-details">
            <h3>Product details: </h3>
            <div className="product-details-fields">
              {productDetails?.map(
                (
                  {
                    label,
                    multiple,
                    options,
                    value,
                    key,
                    error,
                    errorMessage,
                    type = 'dropdown'
                  },
                  index
                ) => {
                  return (
                    <div
                      className="input-field"
                      key={`product-detail-${index}`}
                    >
                      <label className="input-label" htmlFor={key}>
                        <span>{label}</span>
                      </label>
                      <div className="input-value">
                        {type === 'text' ? (
                          <TextInput
                            value={value ?? ''}
                            onChange={e =>
                              changeValueForProductDetails(key, e.target.value)
                            }
                          />
                        ) : (
                          <ReactSelectWrapper
                            inputId={key}
                            isMulti={multiple}
                            options={options}
                            values={value}
                            onChange={value =>
                              changeValueForProductDetails(key, value)
                            }
                          />
                        )}
                        <span
                          className={`error-text ${error ? 'show' : 'hide'}`}
                        >
                          {errorMessage}
                        </span>
                      </div>
                    </div>
                  )
                }
              )}
            </div>
          </div>
          <div ref={imagesDiv} className="product-image">
            <h3>Images</h3>
            {selectedImages.length > 0 && (
              <div className="selected-images-container">
                {selectedImages.map((selectedImage, index) => (
                  <div
                    key={`image-preview-${index}`}
                    className="uploaded-image-preview"
                  >
                    <div className="image-detail">
                      <img
                        alt="Corrupted"
                        src={URL.createObjectURL(selectedImage.file)}
                      />
                      <span className="image-name">{selectedImage.name}</span>
                    </div>
                    <span
                      className="cross-icon top-right"
                      onClick={() => removeImage(index)}
                    >
                      X
                    </span>
                  </div>
                ))}
              </div>
            )}
            <div className="product-image-button-container">
              <label for="product-image" className="product-image-label button">
                Choose images
                <input
                  inputProps={{ accept: 'image/*' }}
                  id="product-image"
                  className="product-image-input"
                  type="file"
                  name="product-image"
                  onChange={event => addImages(event.target)}
                  multiple
                />
              </label>
              <span className={`error-text ${imageError ? 'show' : 'hide'}`}>
                {imageError}
              </span>
            </div>
          </div>
          <div className="specifications">
            <h3>Specifications</h3>
            <div className="specifications-fields">
              {specifications?.map(
                (
                  { label, multiple, options, value, key, error, errorMessage },
                  index
                ) => {
                  return (
                    <div className="input-field" key={`specification-${index}`}>
                      <label className="input-label" htmlFor={key}>
                        <span>{label}</span>
                      </label>
                      <div className="input-value">
                        <ReactSelectWrapper
                          inputId={key}
                          isMulti={multiple}
                          options={options}
                          values={value}
                          onChange={values =>
                            changeValueForSpecification(key, values)
                          }
                        />

                        <span
                          className={`error-text ${error ? 'show' : 'hide'}`}
                        >
                          {errorMessage}
                        </span>
                      </div>
                    </div>
                  )
                }
              )}
            </div>
          </div>
          <div className="custom-attributes">
            <div className="custom-attributes-fields">
              {customAttributes?.map(({ identifier, key, values }, index) => {
                return (
                  <div
                    className="input-field"
                    key={`custom-attributes-${index}`}
                  >
                    <div className="input-key-cancel-wrapper">
                      <TextInput
                        className="text-input"
                        value={key || ''}
                        onChange={e => {
                          changePropertyForCustomAttribute(
                            identifier,
                            e.target.value,
                            'key'
                          )
                        }}
                      />
                      <span
                        className="cross-icon"
                        onClick={() => removeCustomAttribute(identifier)}
                      >
                        X
                      </span>
                    </div>
                    <div className="input-value">
                      <ReactSelectWrapper
                        isMulti={true}
                        onChange={updatedValues =>
                          changePropertyForCustomAttribute(
                            identifier,
                            updatedValues,
                            'value'
                          )
                        }
                        values={values}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
            <button
              className="button"
              disabled={updateLoading}
              type="button"
              onClick={addEmptyCustomAttribute}
            >
              Add more attribute
            </button>
          </div>
          <button className="button" disabled={updateLoading} type="submit">
            {updateLoading ? (
              <Spinner spinnerColor={'var(--light-color)'} />
            ) : (
              'Submit'
            )}
          </button>
        </form>
      </FormWrapper>
    </>
  )
}

export default ProductForm
