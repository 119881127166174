import { useEffect, useMemo, useState } from 'react'
import { UserService } from '../utils/user.service'
import { isNull, isUndefined } from 'lodash'
import { Navigate, Outlet } from 'react-router-dom'

export const PrivateRoute = ({ children, ...rest }) => {
  const userService = useMemo(() => UserService.getInstance(), [])
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState(undefined)
  useEffect(() => {
    if (userService) {
      setToken(userService.getAccessTokenData())
    }
  }, [userService, setToken])

  useEffect(() => {
    if (!isUndefined(token)) {
      setLoading(false)
    }
  }, [token])

  if (loading) return null

  return isNull(token) ? <Navigate to="/login" /> : <Outlet />
}
