import { FaSpinner } from 'react-icons/fa'
import styled from 'styled-components'

const SpinnerWrapper = styled.div`
  ${props =>
    props.fullPage
      ? `
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--light-color);
      pointer-events: none;
  `
      : ``}

  .spinner {
    color: ${props =>
      props.spinnerColor ? props.spinnerColor : 'var(--primary-color)'};
    font-size: ${props => (props.size ? props.size : 'unset')};
    animation: spin infinite 1.2s ease-out;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export const Spinner = ({
  size,
  spinnerColor,
  fullPage = false,
  warpperProps = {}
}) => {
  return (
    <SpinnerWrapper
      fullPage={fullPage}
      size={size}
      spinnerColor={spinnerColor}
      {...warpperProps}
    >
      <FaSpinner icon="spinner-third" className="spinner" />
    </SpinnerWrapper>
  )
}
