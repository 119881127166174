import { flattenObj } from './common'

export const getColumnsFromJsonData = _ => {
  return [
    { Header: 'Attribute', accessor: 'attr' },
    { Header: 'Value', accessor: 'val' }
  ]
}

export const getDataFromJsonData = jsonData => {
  let flat = flattenObj(jsonData)
  return Object.keys(flat).map(key => {
    return {
      attr: key,
      val: flat[key]
    }
  })
  //   , function (str) {
  //   return str.replace(KEY_MODIFIER_REGEX, KEY_MODIFIED_WITH)
  // })
}
