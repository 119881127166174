import styled from 'styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

const ProfileContainer = styled.div`
  .profile {
    margin: 20px;
    background-color: var(--light-color);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 3px var(--dark-color-light);
    display: block;
    width: 95% !important;
    height: 100%;

    img {
      object-fit: scale-down;
      display: inline;
      width: 100%;
      height: 100px;
    }
  }
`
const TeamProfile = ({ items }) => {
  let slidesToShow = items.length
  if (slidesToShow > 5) slidesToShow = 5
  const settings = {
    dots: true,
    centerMode: true,
    infinite: true,
    // centerPadding: '60px',
    slidesToShow: 3,
    speed: 500,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <ProfileContainer>
      <Slider {...settings}>
        {items.map(item => {
          return (
            <div key={item.name} className="profile">
              <img src={item.src} alt={item.name} />
              <div className="profile-name">{item.name}</div>
            </div>
          )
        })}
      </Slider>
    </ProfileContainer>
  )
}

export default TeamProfile
