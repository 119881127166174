import styled from 'styled-components'
import spLogo from '../assets/logo.png'

const LogoWrapper = styled.div``

export const Logo = ({ ...props }) => (
  <LogoWrapper>
    <div>
      <img src={spLogo} alt="logo" />
    </div>
  </LogoWrapper>
)
