import { useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { UserService } from '../utils/user.service'
import { useNavigate } from 'react-router-dom'
import { Spinner } from '../components/Spinner'
const LoginWrapper = styled.div`
  width: 100vw;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-container {
    margin: 20px 0;
    padding: 25px 30px;
    border-radius: 5px;
    border: 0.5px solid gray;
    display: flex;
    flex-direction: column;
    align-items: strech;
    justify-content: space-between;
    max-width: 600px;
    width: 70%;
    color: var(--primary-color);
  }

  .login-form {
    margin: 20px 0 10px 0;
  }

  .form-field:not(:last-child) {
    margin-bottom: 10px;
  }

  .form-field {
    display: flex;
    flex-direction: column;
  }

  input {
    padding: 7px 5px;
    margin-top: 7px;
    border: 1.5px solid;
    border-color: #ababab;
    border-radius: 5px;
  }

  .error {
    display: block;
    text-align: center;
    color: var(--red-color);
    font-size: small;
    transition: all 0.15s ease-in;
    margin-bottom: 10px;
    opacity: 0;
  }

  button {
    width: 100%;
    padding: 9px 5px;
    border-radius: 5px;
    border: none;
    color: var(--light-color);
    background-color: var(--primary-color-light);
    cursor: pointer;
    transition: all 0.3s ease-in;

    &:hover {
      background-color: var(--primary-color);
    }
  }
`

export const Login = () => {
  const [error, setError] = useState('Something went wrong!!')
  const errorRef = useRef()
  const userService = useMemo(() => UserService.getInstance(), [])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const changeErrorState = useCallback(
    (show, message) => {
      if (show) {
        setError(message)
        errorRef.current.style.opacity = 1
      } else {
        errorRef.current.style.opacity = 0
      }
    },
    [setError, errorRef]
  )

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault()
      setLoading(true)
      const email = event.target.email.value
      const password = event.target.password.value

      const emailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/

      if (!emailRegex.test(email)) {
        changeErrorState(true, 'Email is not valid!')
        setLoading(false)
        return
      }

      const { success, message } = await userService.login(email, password)
      if (!success) {
        changeErrorState(true, message)
        setLoading(false)
        return
      }

      navigate('/admin')
    },
    [userService, changeErrorState, navigate]
  )
  return (
    <LoginWrapper>
      <div className="login-container">
        <div className="header">
          <h1>Login</h1>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="login-form">
            <div className="form-field">
              <label>Email:</label>
              <input
                type={'email'}
                name={'email'}
                onChange={() => changeErrorState(false)}
                onFocus={() => changeErrorState(false)}
              />
            </div>
            <div className="form-field">
              <label>Password:</label>
              <input
                type={'password'}
                name={'password'}
                autoComplete={'current-password'}
                onChange={() => changeErrorState(false)}
                onFocus={() => changeErrorState(false)}
              />
            </div>
          </div>
          <span ref={errorRef} className="error">
            {error}
          </span>
          <div className="submit-container">
            <button disabled={loading}>
              {loading ? <Spinner /> : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </LoginWrapper>
  )
}
