import placeholder from '../assets/placeholder.jpeg'
import { isEmpty, isEqual } from 'lodash'
import { GithubService } from './github.service'
import { UserService } from './user.service'
import ImageKit from 'imagekit'

export const IMAGE_PLACEHOLDER = placeholder
export const ALLOWED_FILE_SIZE_IN_BYTES = 2000000
export const ALLOWED_IMAGE_EXTENSIONS = ['png', 'jpg', 'jpeg']

const githubService = GithubService.getInstance()
const userService = UserService.getInstance()

export class ProductCatalogue {
  _productCatalogue
  static _instance = null

  constructor() {
    this._productCatalogue = []
  }

  static getInstance = function () {
    if (!isEmpty(this._instance)) return this._instance
    this._instance = new ProductCatalogue()
    return this._instance
  }

  updateNewProduct = async function (product, isNewCategory) {
    const productCatalogue = await this.getProductCategory()
    if (isNewCategory) {
      productCatalogue.push({
        id: product.category_id,
        name: product.category_name,
        description: '',
        items: [product],
        total: 1
      })
    } else {
      const category = productCatalogue.find(
        pc => pc.id === product.category_id
      )
      category.items.push(product)
      category.total += 1
    }

    const token = userService.getAccessTokenData()

    const commitMessage = `Inserted product: ${product.name}(${product.id}) by ${token.sub}`

    await githubService.putContent(
      process.env.REACT_APP_GITHUB_PRODUCT_FILE,
      productCatalogue,
      commitMessage
    )
    this._productCatalogue = productCatalogue
    return true
  }

  updateExistingProduct = async function (product) {
    const productCatalogue = await this.getProductCategory()
    const category = productCatalogue.find(pc => pc.id === product.category_id)
    const index = category.items.findIndex(
      _product => _product.id === product.id
    )

    if (index < 0) {
      throw new Error(`Can't find the product with id: ${product.id}`)
    }
    category.items[index] = product

    const token = userService.getAccessTokenData()

    const commitMessage = `Updated product: ${product.id} by ${token.sub}`

    await githubService.putContent(
      process.env.REACT_APP_GITHUB_PRODUCT_FILE,
      productCatalogue,
      commitMessage
    )
    this._productCatalogue = productCatalogue
    return true
  }

  fetchProductCategory = async function () {
    return githubService.getRawContent(
      process.env.REACT_APP_GITHUB_PRODUCT_FILE
    )
  }

  getProductCategory = async function () {
    if (!isEmpty(this._productCatalogue)) return this._productCatalogue
    this._productCatalogue = await this.fetchProductCategory()
    return this._productCatalogue
  }

  getCategoryFromId = async function (categoryId) {
    const productCatalogue = await this.getProductCategory()
    return productCatalogue.find(
      category => String(category.id) === String(categoryId)
    )
  }

  getProductOfCategoryFromId = async function (categoryId, productId) {
    const productsFromCategory = (await this.getCategoryFromId(categoryId))
      .items
    return productsFromCategory.find(
      product => String(product.id) === String(productId)
    )
  }

  checkUniqueProductId = async function (productId) {
    const productCatalogue = await this.getProductCategory()
    for (let category of productCatalogue) {
      const product = category.items?.find(
        product => product.id?.toString() === productId?.toString()
      )
      if (!isEmpty(product)) {
        return false
      }
    }
    return true
  }

  uploadImage = async function (base64Img, fileName, tags = []) {
    return new Promise(function (resolve, reject) {
      const {
        REACT_APP_IMAGEKIT_PUBLIC_KEY,
        REACT_APP_IMAGEKIT_PRIVATE_KEY,
        REACT_APP_IMAGEKIT_URL,
        REACT_APP_IMAGEKIT_FOLDER
      } = process.env

      if (
        isEmpty(REACT_APP_IMAGEKIT_PUBLIC_KEY) ||
        isEmpty(REACT_APP_IMAGEKIT_PRIVATE_KEY) ||
        isEmpty(REACT_APP_IMAGEKIT_URL) ||
        isEmpty(REACT_APP_IMAGEKIT_FOLDER)
      ) {
        throw Error('No configuration available for the image upload!!')
      }

      const imagekit = new ImageKit({
        publicKey: REACT_APP_IMAGEKIT_PUBLIC_KEY,
        privateKey: REACT_APP_IMAGEKIT_PRIVATE_KEY,
        urlEndpoint: REACT_APP_IMAGEKIT_URL
      })

      imagekit.upload(
        {
          file: base64Img,
          fileName,
          tags,
          folder: REACT_APP_IMAGEKIT_FOLDER
        },
        function (error, result) {
          /* Result object 
          
          {
              "fileId": "62e13e7f8b43815f1ff4973e",
              "name": "product_23243523_eqSqsNSFZ-ex-24ZWE3iM_2leJhaYQS.png",
              "size": 634298,
              "versionInfo": {
                  "id": "62e13e7f8b43815f1ff4973e",
                  "name": "Version 1"
              },
              "filePath": "/switchpoint/product/product_23243523_eqSqsNSFZ-ex-24ZWE3iM_2leJhaYQS.png",
              "url": "https://ik.imagekit.io/rddurctbb/switchpoint/product/product_23243523_eqSqsNSFZ-ex-24ZWE3iM_2leJhaYQS.png",
              "fileType": "image",
              "height": 1079,
              "width": 1800,
              "thumbnailUrl": "https://ik.imagekit.io/rddurctbb/tr:n-ik_ml_thumbnail/switchpoint/product/product_23243523_eqSqsNSFZ-ex-24ZWE3iM_2leJhaYQS.png",
              "AITags": null
          }
          
          
          */
          if (error) {
            reject(error)
            return
          }

          resolve({
            url: result.url,
            thumbnailUrl: result.thumbnailUrl,
            fileId: result.fileId,
            name: result.name
          })
        }
      )
    })
  }

  deleteImages = async function (fileIds) {
    return new Promise(function (resolve, reject) {
      const {
        REACT_APP_IMAGEKIT_PUBLIC_KEY,
        REACT_APP_IMAGEKIT_PRIVATE_KEY,
        REACT_APP_IMAGEKIT_URL,
        REACT_APP_IMAGEKIT_FOLDER
      } = process.env

      if (
        isEmpty(REACT_APP_IMAGEKIT_PUBLIC_KEY) ||
        isEmpty(REACT_APP_IMAGEKIT_PRIVATE_KEY) ||
        isEmpty(REACT_APP_IMAGEKIT_URL) ||
        isEmpty(REACT_APP_IMAGEKIT_FOLDER)
      ) {
        throw Error('No configuration available for the image upload!!')
      }

      const imagekit = new ImageKit({
        publicKey: REACT_APP_IMAGEKIT_PUBLIC_KEY,
        privateKey: REACT_APP_IMAGEKIT_PRIVATE_KEY,
        urlEndpoint: REACT_APP_IMAGEKIT_URL
      })

      imagekit
        .bulkDeleteFiles(fileIds)
        .then(_ => {
          resolve()
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log(error)

          // TODO: Solve CORS Error
          resolve()
        })
    })
  }

  deleteProduct = async function (productId, categoryId) {
    let productCatalogue = await this.getProductCategory()
    const category = productCatalogue.find(pc => pc.id === categoryId)
    const updatedProductList = category.items.filter(
      product => product.id !== productId
    )
    if (updatedProductList?.length === 0) {
      // remove category if no items present after removing current product
      productCatalogue = productCatalogue.filter(pc => pc.id !== categoryId)
      ProductAttributes.getInstance().deleteCategory(categoryId)
    } else {
      category.items = updatedProductList
      category.total -= 1
    }
    const token = userService.getAccessTokenData()

    const commitMessage = `Deleted product: (${productId}) from category: (${categoryId}) by ${token.sub}`

    await githubService.putContent(
      process.env.REACT_APP_GITHUB_PRODUCT_FILE,
      productCatalogue,
      commitMessage
    )
    this._productCatalogue = productCatalogue
    return true
  }
}

export class ProductAttributes {
  _productAttributes
  static _instance = null

  constructor() {
    this._productAttributes = {}
  }

  static getInstance = function () {
    if (!isEmpty(this._instance)) return this._instance
    this._instance = new ProductAttributes()
    return this._instance
  }

  fetchAttributes = async function () {
    return githubService.getRawContent(
      process.env.REACT_APP_GITHUB_ATTRIBUTE_FILE
    )
  }

  getAttributes = async function () {
    if (!isEmpty(this._productAttributes)) return this._productAttributes
    this._productAttributes = await this.fetchAttributes()
    return this._productAttributes
  }

  updateProductAttributes = async function (
    updatedAttributes,
    productId = null
  ) {
    if (isEqual(updatedAttributes, this.getAttributes())) {
      return
    }

    const token = userService.getAccessTokenData()
    if (isEmpty(token)) {
      throw Error('User not logged in')
    }

    const commitMessage = `Update for product: ${productId} by: ${token.sub}`
    await githubService.putContent(
      process.env.REACT_APP_GITHUB_ATTRIBUTE_FILE,
      updatedAttributes,
      commitMessage
    )

    this._productAttributes = updatedAttributes

    return true
  }

  deleteCategory = async function (categoryId) {
    const updatedAttributes = await this.getAttributes()
    updatedAttributes.product_details.forEach(product_detail => {
      if (product_detail.key === 'category_id') {
        product_detail.options = product_detail.options?.filter(
          option => option.value !== categoryId
        )
      }
    })

    const token = userService.getAccessTokenData()
    if (isEmpty(token)) {
      throw Error('User not logged in')
    }

    const commitMessage = `Removed category: ${categoryId} by: ${token.sub}`
    await githubService.putContent(
      process.env.REACT_APP_GITHUB_ATTRIBUTE_FILE,
      updatedAttributes,
      commitMessage
    )

    this._productAttributes = updatedAttributes
  }
}
