import React, { useState } from 'react'
import 'animate.css/animate.min.css'
import Navbar from './components/Navbar'
import Main from './Pages/Main'
import { ParallaxProvider } from 'react-scroll-parallax'
import Footer from './components/Footer'
import ThemeSwitcher from './components/ThemeSwitcher'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NotFound from './Pages/NotFound'
import './assets/css/global.slick.slider.scss'
import CategoryDetails from './Pages/CategoryDetails'
import ProductDetails from './Pages/ProductDetails'
import Admin from './Pages/Admin'
import AddProduct from './Pages/AddProduct'
import EditProduct from './Pages/EditProduct'
import { Login } from './Pages/Login'
import { PrivateRoute } from './components/PrivateRoute'

function App() {
  const [activePage, setActivePage] = useState('home')

  return (
    <>
      <BrowserRouter>
        <Navbar activePage={activePage} setActivePage={setActivePage} />
        <ParallaxProvider>
          <Routes>
            <Route path="/" element={<Main setActivePage={setActivePage} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/category/:categoryId" element={<CategoryDetails />} />
            <Route
              path="/category/:categoryId/product/:productId"
              element={<ProductDetails />}
            />

            {/* Protected routes: can only be accessible if logged in */}
            <Route element={<PrivateRoute />}>
              <Route path="/admin" element={<Admin />} />
              <Route path="/admin/product/add" element={<AddProduct />} />
              <Route
                path="/admin/product/edit/:categoryId/:productId"
                element={<EditProduct />}
              />
            </Route>
            {/* Not found */}
            <Route path="*" element={<NotFound />} />

            {/* Extra routes: just for devs */}
            <Route
              path="/chooseThemes"
              element={
                <>
                  <ThemeSwitcher />
                  <Main setActivePage={setActivePage} />
                </>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ParallaxProvider>
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default App
